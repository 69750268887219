<template>
  <div>
    <div class="header-main">
    <slot name="title"/>
    </div>
    <template>
      <div class="px-main">
        <v-tabs v-model="tab" class="t-tab-custom">
          <v-tab v-for="(item, i) in items" :key="i" :to="item.to">{{
            item.title
          }}</v-tab>
        </v-tabs>
        <slot name="default" />
      </div>
    </template>
  </div>
</template>

<script>
import { checkPermissionTab } from '@/utils/permissions'

export default {
  name: 'BaseFacility',
  data () {
    return {
      tab: null,
      items: [],
      itemsTmp: [
        {
          title: '施設設定',
          to: '/facility/settings',
          pathRoot: '/facility/settings',
          order: 1
        },
        {
          title: this.$t('routeTitles.facility.rentalList'),
          to: '/facility/rental-list',
          pathRoot: '/facility/rental-list',
          order: 2
        },
        {
          title: this.$t('routeTitles.facility.productsForSale'),
          to: '/facility/products-for-sale',
          pathRoot: '/facility/products-for-sale',
          order: 3
        }
      ]
    }
  },
  mounted () {
    this.checkPerTab()
  },
  methods: {
    checkPermissionTab,
    checkPerTab () {
      let itemCheck = this.checkPermissionTab(this.itemsTmp)
      itemCheck = [...new Set(itemCheck)]
      itemCheck.sort((a, b) => a.order - b.order)
      this.items = itemCheck
    }
  },
  components: {}
}
</script>

<style lang="scss"></style>
